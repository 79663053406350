import { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import {
    DesktopOutlined,
    UserOutlined,
    ProfileOutlined,
    LogoutOutlined,
    HistoryOutlined,
    AppstoreOutlined,
    CameraOutlined,
    SettingOutlined,
    ShoppingOutlined,
    HomeOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isRootOrTopAdmin } from '../../helpers/user';
import { logout } from "../../actions/auth";
import { readEntities } from '../../actions/entity';
import { useTranslation } from 'react-i18next';


function MemberSidebar() {
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user);
    const entities = useSelector(state => state.entity.entities);

    const { Sider } = Layout;
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (!entities) {
            dispatch(readEntities());
        }
    }, [currentUser.isLoggedIn, entities, dispatch]);

    let items = [];

    if (location.pathname.indexOf('/settings') === 0) {
        if (isRootOrTopAdmin(currentUser)) {
            items.push({
                key: 'entities',
                icon: <AppstoreOutlined />,
                label: <Link to="/settings/entities">{t('sidebar.entity-management')}</Link>,
            });
            items.push({
                key: 'admins',
                icon: <UserOutlined />,
                label: <Link to="/settings/admins">{t('sidebar.admins')}</Link>,
            });
            items.push({
                key: 'roles',
                icon: <UserOutlined />,
                label: <Link to="/settings/roles">{t('sidebar.roles')}</Link>,
            });
            items.push({
                key: 'log',
                icon: <HistoryOutlined />,
                label: <Link to="/settings/logs">{t('sidebar.logs')}</Link>,
            });
        }
        items.push({
            key: 'profile',
            icon: <ProfileOutlined />,
            label: <Link to="/profile">{t('sidebar.profile')}</Link>,
        });
        items.push({
            key: 'logout',
            icon: <LogoutOutlined />,
            label: <div onClick={() => dispatch(logout())}>{t('sidebar.logout')}</div>,
        });
    } else {
        items.push({
            key: 'switch-entity',
            icon: <DesktopOutlined />,
            label: <Link to="/dashboard">{t('sidebar.switch-entity')}</Link>,
        });
        if (entities && entities.length > 0 && location.pathname.indexOf('/entity') === 0) {
            if (typeof params.entityId !== 'undefined') {
                let entity = entities.find(entity => entity.id === params.entityId);
                items.push({
                    key: `${entity.id}-orders`,
                    icon: <ShoppingOutlined />,
                    label: <Link to={`/entity/${entity.id}/orders`}>{t('sidebar.entity.orders')}</Link>,
                });
                items.push({
                    key: `${entity.id}-users`,
                    icon: <UserOutlined />,
                    label: <Link to={`/entity/${entity.id}/users`}>{t('sidebar.entity.users')}</Link>,
                });
                items.push({
                    key: `${entity.id}-companions`,
                    icon: <UserOutlined />,
                    label: <Link to={`/entity/${entity.id}/companions`}>{t('sidebar.entity.companions')}</Link>,
                });
                // Promotion
                items.push({
                    key: `${entity.id}-trips`,
                    icon: <CameraOutlined />,
                    label: t('sidebar.entity.trips'),
                    children: [
                        {
                            key: `${entity.id}-trips-all`,
                            label: <Link to={`/entity/${entity.id}/trips`}>{t('sidebar.entity.trips')}</Link>,
                        },
                        {
                            key: `${entity.id}-trips-collections`,
                            label: <Link to={`/entity/${entity.id}/trip-collections`}>{t('sidebar.entity.trips.collections')}</Link>,
                        },
                        {
                            key: `${entity.id}-trips-topics`,
                            label: <Link to={`/entity/${entity.id}/trip-topics`}>{t('sidebar.entity.trips.topics')}</Link>,
                        },
                        {
                            key: `${entity.id}-trips-regions`,
                            label: <Link to={`/entity/${entity.id}/trip-regions`}>{t('sidebar.entity.trips.regions')}</Link>,
                        },
                        {
                            key: `${entity.id}-trips-countries`,
                            label: <Link to={`/entity/${entity.id}/trip-countries`}>{t('sidebar.entity.trips.countries')}</Link>,
                        },
                        {
                            key: `${entity.id}-trips-holidays`,
                            label: <Link to={`/entity/${entity.id}/trip-holidays`}>{t('sidebar.entity.trips.holidays')}</Link>,
                        },
                        {
                            key: `${entity.id}-trips-types`,
                            label: <Link to={`/entity/${entity.id}/trip-types`}>{t('sidebar.entity.trips.types')}</Link>,
                        },
                        {
                            key: `${entity.id}-trips-tags`,
                            label: <Link to={`/entity/${entity.id}/trip-tags`}>{t('sidebar.entity.trips.tags')}</Link>,
                        },
                    ]
                });
                items.push({
                    key: `${entity.id}-discussions`,
                    icon: <AppstoreOutlined />,
                    label: <Link to={`/entity/${entity.id}/discussions`}>{t('sidebar.entity.discussion')}</Link>,
                });
                items.push({
                    key: `${entity.id}-articles`,
                    icon: <AppstoreOutlined />,
                    label: t('sidebar.entity.articles'),
                    children: [
                        {
                            key: `${entity.id}-articles-all`,
                            label: <Link to={`/entity/${entity.id}/articles`}>{t('sidebar.entity.articles')}</Link>,
                        },
                        {
                            key: `${entity.id}-article-tags`,
                            label: <Link to={`/entity/${entity.id}/article-tags`}>{t('sidebar.entity.articles.tags')}</Link>,
                        },
                        {
                            key: `${entity.id}-article-categories`,
                            label: <Link to={`/entity/${entity.id}/article-categories`}>{t('sidebar.entity.articles.categories')}</Link>,
                        },
                    ]
                });
                items.push({
                    key: `${entity.id}-fixed-pages`,
                    icon: <AppstoreOutlined />,
                    label: <Link to={`/entity/${entity.id}/fixed-pages`}>{t('sidebar.entity.fixed-pages')}</Link>,
                });
                items.push({
                    key: `${entity.id}-pages`,
                    icon: <AppstoreOutlined />,
                    label: <Link to={`/entity/${entity.id}/pages`}>{t('sidebar.entity.pages')}</Link>,
                });
                items.push({
                    key: `${entity.id}-media`,
                    icon: <AppstoreOutlined />,
                    label: <Link to={`/entity/${entity.id}/media`}>{t('sidebar.entity.media')}</Link>,
                });
                items.push({
                    key: `${entity.id}-user-media`,
                    icon: <AppstoreOutlined />,
                    label: <Link to={`/entity/${entity.id}/user-media`}>{t('sidebar.entity.user-media')}</Link>,
                });
                items.push({
                    key: `${entity.id}-config`,
                    icon: <SettingOutlined />,
                    label: <Link to={`/entity/${entity.id}/configs`}>{t('sidebar.entity.configs')}</Link>,
                });
            }
        }
        items.push({
            key: 'logout',
            icon: <LogoutOutlined />,
            label: <div onClick={() => dispatch(logout())}>{t('sidebar.logout')}</div>,
        });
    }

    // TODO: check if enableCrm is true or false
    return <Sider width={250} collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        <div className="logo">
            <div>eastern vision</div>
            <div>
                {isRootOrTopAdmin(currentUser) && location.pathname.indexOf('/settings') === -1 && <Link to="/settings"><SettingOutlined /></Link>}
                {isRootOrTopAdmin(currentUser) && location.pathname.indexOf('/settings') === 0 && <Link to="/dashboard"><HomeOutlined /></Link>}
            </div>
        </div>
        {/* openKeys={false && entities && entities.length > 0 && entities.map(entity => entity.id)} */}
        <Menu theme="dark" mode="inline" items={items} />
    </Sider>;
}

export default MemberSidebar;